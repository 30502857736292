import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/cellusys-technologies/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Row mdxType="Row">
  <Block mdxType="Block">
    <Heading mdxType="Heading">NOT FOUND</Heading>
    <p>Sorry, this page doesn't exist.</p>
  </Block>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      